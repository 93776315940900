import _ from "lodash";

export function kbbtsToMbs (kbts){
    var rs =  ((parseInt (kbts)) / 1024)/1024;    
    return rs.toFixed(2);
}

export function number (value) {
    return _.floor(value, 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}
