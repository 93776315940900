let prefix = '/api/cid';
function read({file}) {
    let formData = new FormData()
    formData.append('files[0]', file);
    return window.axios.post(prefix+'/documents/read', formData, { headers: { 'content-type': 'multipart/form-data' } });
}
function save(date){
    return window.axios.post(prefix+'/documents/save', {'date': date})
}
export default {
    read,
    save,
}