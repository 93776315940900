<template>
    <div>
        <v-app-bar app clipped-left fixed>
            <span class="title"><span class=""></span></span>
            <v-spacer></v-spacer>
            <v-row v-if="config.isLoggedIn">
                <v-col sm="1" md="1" lg="1" xl="1"  offset-sm="11" offset-md="11" offset-lg="11" offset-xl="11">
                    <v-tooltip bottom>Mi perfil
                        <template v-slot:activator="{ on, attrs }">
                            <v-avatar size="36px" @click.stop="drawerConf = !drawerConf" v-bind="attrs" v-on="on">
                                <img alt="" :src="checkImage(userAuth.photoUrl)">
                            </v-avatar>
                        </template>
                    </v-tooltip>
                </v-col>                
            </v-row>

            <v-row v-else>                
                <v-col sm="2" md="2" lg="2" xl="2" offset-sm="6" offset-md="6" offset-lg="6" offset-xl="6">
                    <v-btn block class="primary" outlined @click="goTo('login')" v-if="showhere('/login')">Iniciar Sesión</v-btn>
                </v-col>
                <v-col sm="2" md="2" lg="2" xl="2">
                    <v-btn block class="primary" outlined @click="goTo('register')" v-if="showhere('/register')">Crear Cuenta</v-btn>
                </v-col>
                <v-col sm="2" md="2" lg="2" xl="2">
                    <v-btn block class="primary" outlined @click.stop="drawerConf = !drawerConf">Menu</v-btn>
                </v-col>
            </v-row>
            
        </v-app-bar>

        <v-navigation-drawer v-model="drawerConf" temporary fixed right>
            <template v-slot:prepend>
                <v-list-item class="px-4 pt-1">
                    <v-list-item-title>Opciones</v-list-item-title>
                    <v-btn icon @click.stop="drawerConf = !drawerConf">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-list-item>
            </template>
            <v-divider></v-divider>

            <v-list nav dense>
                <v-subheader>Only test</v-subheader>
                <v-list-item link @click="goTo('public')">
                    <v-list-item-icon>
                    <v-icon>mdi-search-web</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>showroom</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="goTo('cid')">
                    <v-list-item-icon>
                    <v-icon>mdi-chart-box</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>CID</v-list-item-title>
                </v-list-item>
                <v-subheader></v-subheader>
                <v-list-item link>
                    <v-list-item-content>
                        <v-list-item-icon>
                            <v-icon>mdi-brightness-6</v-icon>
                        </v-list-item-icon>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-switch v-model="get_is_dark" v-on:change="setDarkMode(get_is_dark)"></v-switch>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
         </v-navigation-drawer>
    </div>
</template>

<script>
    import default_profile_picture from '../users/images/default_profile_picture.png';

    import { mapState, mapActions } from 'vuex';

    export default {
    props: ['config'],
    data: () => ({
        drawer: true,
        group: null,
        drawerConf: false,
    }),
    components : {
        
    },
    computed: {
        ...mapState('authentication', ['userAuth']),
        logged() {
            return (this.$route.name !== "home")
        },
        sideMenu() {
            return this.$vuetify.breakpoint.width <= 1264 && this.logged
        },
        get_is_dark: {
            get: function () {
                return this.config.themeDark;
            },
            set: function (newValue) {
                return newValue;
            }
        }
    },
    methods: {
        ...mapActions('configuration', ['setDarkMode']),
        logout(){
            this.$router.push({ name: "logout" });
        },
        goTo(name){
            this.$router.push({ name : name }).catch(error => {
                if (error.name != "NavigationDuplicated") {
                    throw error;
                }
            });
        },        
        showhere(route){
            return (this.$route.fullPath != route) ? true : false;
        },
        checkImage(image){
            if(image == 'default_user_image.jpg' || image == 'default_profile_picture.png' || image == null){
                return default_profile_picture;                    
            }else{
                return image;
            }
        }        
    },
    watch: {
        get_is_dark: function (val) {
            this.$vuetify.theme.dark = val;
        }
    },
    mounted(){
        this.$vuetify.theme.dark = this.get_is_dark;
    }
}
</script>

<style>
    .router-link-exact-active {
        background-color: rgba(0, 0, 0, .1);
        border-radius: 5px;
        color: #1867c0 !important;
        caret-color: #1867c0 !important;
        font-style: italic;
        transition: .3s cubic-bezier(.25,.8,.5,1);
    }
</style>