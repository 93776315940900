
const namespaced = true


const state = {
    uploadState: false,

}
const mutations = {
    SET_STATUS(state, val){
        state.uploadState = val;
    },
}
const actions = {}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
