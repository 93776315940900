const namespaced = true

import api from '../../api/cid.js';

const state = {}
const mutations = {}
const actions = {     
    read(context, data) {
        return new Promise((resolve, reject) => {
            api.read(data).then(async function (res) {
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Preview de información' }, { root: true });
                resolve(res.data);
            }).catch((error) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
                reject(null);
            });
        })
    },
    save(context, {date}){
        return new Promise((resolve, reject) => {
            api.save(date).then(() => {
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Información guardada correctamente' }, { root: true });
                resolve(true);
            }).catch(() => {
                reject(null);
            });
        });
    }
}
const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}