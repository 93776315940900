import Vue from 'vue';  
import Router from 'vue-router'

Vue.use(Router);

const routerOptions = [
  {
    path: '/',
    name: 'public',
  },
  {
    path: '/login',
    name: 'login',
  },
  {
    path: '/register',
    name: 'register',
  },
  {
    path: '/cid',
    name: 'cid',
  },
]

// Rutas
const routes = routerOptions.map(r => {
  return {
    ...r,
    component: () => import(`../components/${r.name}/index-component.vue`)
  }
})

const router = new Router({
  mode: 'history',
  base: __dirname,
  routes
})

export default router